<template>
  <BaseLayout>
    <template #primarySection>
      <section
        aria-labelledby="primary-heading"
        class="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last bg-secondary p-10"
      >
        <slot class="mt-12" />
        <SharedCustomNotification />
      </section>
    </template>
  </BaseLayout>
</template>

<script setup>
import BaseLayout from '~/components/shared/BaseLayout.vue'
import { useCurrentUserSession, useCurrentServiceprovider } from '~/composables/states'

const { userOrganization } = useCurrentUserSession()
const route = useRoute()
const { setServiceproviderUUID } =
  useCurrentServiceprovider()

storeProviderUUID()

function storeProviderUUID () {
  let providerUUID = userOrganization

  if (!providerUUID) {
    providerUUID = route.params?.providerId
  }

  setServiceproviderUUID(providerUUID)
}
</script>

<style></style>
